/* App general styling */
.App {
  text-align: center;
  position: relative;
}

/* Header styling */
.App-header {
  background-color: #02152B;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}

/* Logo styling */
.App-logo {
  width: 150px;
  align-self: flex-start;
}

/* Logout button position */
.logout-button {
  align-self: flex-end;
  margin-bottom: 1rem; /* Add margin for separation from the nav */
}

/* Footer styling */
.App-footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #02152B; /* Set your preferred background color */
  color: white; /* Set your preferred text color */
  padding: 10px; /* Add padding as needed */
  text-align: center; /* Center the content horizontally */
}

.App-footer a {
  margin: 0 10px;
  color: #fff;
  text-decoration: none;
  font-size: 30px;
}

.App-footer a:hover {
  text-decoration: underline;
}

/* Navigation styling */
nav {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  background-color: #02152B;
  color: white;
  width: 100%;
}

.nav-link {
  text-decoration: none;
  color: white;
  padding: 0.5rem 1rem;
  transition: background-color 0.3s ease;
}

.nav-link:hover {
  background-color: #697A7C;
  color: #F9A51A;
}

.logout-button {
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  margin-left: 20px;
  cursor: pointer;
}

/* Media query for screen size less than or equal to 600px */
@media screen and (max-width: 600px) {
  nav {
    flex-direction: column;
  }
}

.header-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}


@media (max-width: 600px) {
  .App-footer {
    position: static; /* or any other positioning that works for mobile */
    margin-top: 20px; /* Add margin to create space between button and footer */
  }
}